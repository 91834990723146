@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Calistoga&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
:root
{
  --theme-orange: rgb(255, 115, 0);
  --theme-orange-transparent: rgba(255, 115, 0, 0.444);
  --theme-opac-background: linear-gradient(to right , rgba(0, 0, 0, 0.354) , rgba(255, 115, 0, 0.444));
  --theme-black: black;
  --theme-grey: #e9e6e6;
}
*
{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "PT Sans", sans-serif;
  font-weight: 600;
  font-style: normal;
}
.myborder
{
  border: 5px solid red;
}
.display-flex
{
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.display-flex-column
{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.display-flex-center
{
  display: flex;
  align-items: center;
  justify-content: center;
}
.display-flex-start
{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.display-flex-end
{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.display-flex-between
{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.display-flex-align-items-top
{
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}
.display-flex-align-items-bottom
{
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
}
.fs-smallest-css
{
  font-size: 0.4em;
}
.fs-smaller-css
{
  font-size: 0.6em;
}
.fs-slight-small-css
{
  font-size: 0.9em;
}
.fs-small-css
{
  font-size: 0.8em;
}
.fs-medium-css
{
  font-size: 1.5em;
}
.fs-large-css
{
  font-size: 2em;
}
.fs-larger-css
{
  font-size: 3em;
}
.fs-largest-css
{
  font-size: 4em;
}
.color-theme-orange
{
  color: var(--theme-orange);
}
.color-theme-black
{
  color: var(--theme-black);
}
.color-theme-grey
{
  color: #888787;
}
.bg-theme-grey
{
  background-color: var(--theme-grey);
}
.bg-theme-orange
{
  background-color: var(--theme-orange);
}
.bg-theme-black
{
  background-color: var(--theme-black);
}
.bg-theme-orange-transparent
{
  background-color: var(--theme-orange-transparent);
}
.navbar-position
{
  position: sticky;
  top: 0px;
  z-index: 99;
  background-color: white;
}
.btn-navbar-converse
{
  background-color: var(--theme-black);
  color: white;
  padding: 15px 20px;
  border: 1px solid transparent;
  outline: none;
  transition: all 0.6s ease-in-out;
}
.btn-navbar-converse-mod
{
  background-color: var(--theme-black);
  color: white;
  padding: 5px 20px;
  border: 1px solid transparent;
  outline: none;
  transition: all 0.6s ease-in-out;
}
.btn-navbar
{
  background-color: var(--theme-orange);
  color: white;
  padding: 15px 20px;
  height: 100%;
  border: none;
  font-weight: 500;
  /* font-size: ; */
  outline: none;
  transition: all 0.6s ease-in-out;
}
.btn-navbar:hover , .btn-navbar:focus
{
  background-color: var(--theme-black);
  color: white;
  padding: 15px 20px;
  border: none;
  outline: none;
}
.btn-navbar-converse:hover , .btn-navbar-converse:focus
{
  background-color: var(--theme-orange);
  color: var(--theme-black);
  padding: 15px 20px;
  border: 1px solid var(--theme-black);
  outline: none;
}
.btn-navbar-converse-mod:hover , .btn-navbar-converse-mod:focus
{
  background-color: var(--theme-orange);
  color: var(--theme-black);
  padding: 5px 20px;
  border: 1px solid var(--theme-black);
  outline: none;
}
@media (min-width:900px) and (max-width:5000px)
{
  .display-on-large-screen
  {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .display-on-small-screen
  {
    display: none;
  }
}
@media (min-width:0px) and (max-width:900px)
{
  .display-on-large-screen
  {
    display: none;
  }
  .display-on-small-screen
  {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
.small-screen-navbar
{
  position: fixed;
  top: 0px;
  left: 0px;
  transform-origin: left;
  transition: all 0.6s;
  background-color: white;
  z-index: 99;
}
.scale-x-0
{
  transform: scaleX(0);
}
.scale-x-1
{
  transform: scaleX(1);
}
.slider
{
  position: relative;
}
.slide
{
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  object-fit: cover;
  height: 60vh;
  opacity: 0;
  transition: all 1s;
}
.slide.active
{
  opacity: 1;
}
.height-60vh
{
  height: 60vh;
}
.btn-carousel
{
  background-color: black;
  border: 1px solid white;
  color: white;
  height: 50px;
  width: 50px;
  outline: none;
  margin: 0px 20px;
  transition: all 0.6s ease-in-out;
}
.btn-carousel:hover
{
  background-color: var(--theme-orange);
  border: 1px solid var(--theme-orange);
  color: black;
}
.btn-carousel:disabled
{
  opacity: 0.7;
  background-color: black;
  border: 1px solid white;
  color: white;
  height: 50px;
  width: 50px;
  outline: none;
  margin: 0px 20px;
  transition: all 0.6s ease-in-out;
}
.slider-products
{
  position: relative;
}
@media (min-width:767px) and (max-width:5000px)
{
  .slider-products
  {
    height: 100vh;
  }
  .products-image-section
{
  position: sticky;
  top: 20vh;
  left: 0px;
}
}

@media (min-width:0px) and (max-width:767px)
{
  .slider-products
  {
    height: 300vh;
  }
  .products-image-section
  {
    position: static;
  }
}
.slide-products
{
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0;
  transition: all 1s ease-in-out;
  z-index: -1;
}
.slide-products.active
{
  opacity: 1;
  z-index: 99;
}
.z-index-999
{
  z-index: 999;
}
.position-relative
{
  position: relative;
  overflow: hidden;
}
.nah-div-1
{
  position: absolute;
  left: -20vw;
  top: 0px;
  height: 100%;
  background-image: url("https://soltechindia.co.in/images/mainpage-bg-image-1.jpg");
  /* background-size: cover; */
  background-repeat: no-repeat;
  z-index: -1;
}
.nah-div-2
{
  position: absolute;
  right: -50vw;
  top: 0px;
  height: 100%;
  background-color: white;
  transform: skewX(-30deg);
  z-index: 0;
}
.nah-div-2-content
{
  width: 40%;
  transform: skewX(30deg);
  z-index: 1;
}
.nah-btn-center
{
  position: absolute;
  top: center;
  left: center;
  z-index: 2;
}
.btn-help
{
  margin-right: -30px;
  width: 80px;
  background-color: var(--theme-orange);
  color: white;
  border: none;
  outline: none;
  height: 80px;
  z-index: 3;
}
.height-nah
{
  height: 40vh;
}
.nah-connection-image 
{
  z-index: 999;
  width: 25%;
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index: 0;
  opacity: 0.3;
}
.o-fit-cover
{
  object-fit: cover;
}
.contact-us-address-icon
{
  height: 80px;
  width: 80px;
  background-color: var(--theme-orange);
  color: white;
  border: none;
  outline: none;
  font-size: 1.5em;
}
.input-field
{
  color: black;
  background-color: white;
  font-size: 1em;
  border: none;
  outline: 1px solid transparent;
  padding: 15px 20px;
}
.input-field.wrong-input
{
  color: red;
  background-color: white;
  font-size: 1em;
  border: none;
  outline: 1px solid red;
  padding: 15px 20px;
}
.text-align-justify
{
  text-align: justify;
}


/* TestimonialSlider.css */
.testimonial-slider {
  width: 100%;
  overflow: scroll;
  position: relative;
}
.client-logo
{
  width: 100%;
  height: 23vh;
  object-fit: contain;
}
.client-logo-container
{
  width: 90%;
  height: 25vh;
}
.testimonial-container {
  /* display: flex; */
  transition: transform 1s ease-in-out;
  /* position: relative; */
}
.border-left-orange
{
  border-left: 0.5px solid var(--theme-orange);
}
.testimonial-css {
  /* width: 100%; */
  /* box-sizing: border-box; */
  /* padding: 20px; */
  /* background-color: #f5f5f5; */
  text-align: left;
  font-size: 0.9em;
  border: none;
}
.testimonial-image 
{
  height: 100px;
  width: 100px;
  object-fit: cover;
}
/* Dynamic classes to control the sliding effect */
.index-0 { transform: translateX(0%); }
.index-1 { transform: translateX(-100%); }
.index-2 { transform: translateX(-200%); }
.index-3 { transform: translateX(-300%); }
.index-4 { transform: translateX(-400%); }
.index-5 { transform: translateX(-500%); }
.index-6 { transform: translateX(-600%); }
.index-7 { transform: translateX(-700%); }
.index-8 { transform: translateX(-800%); }
.index-9 { transform: translateX(-900%); }
.index-10 { transform: translateX(-1000%); }

.overflow-hidden
{
  overflow: hidden;
}


.btn-footer
{
  background-color: transparent;
  color: var(--theme-white);
  font-size: 0.9em;
  padding: 0px;
  font-weight: 600;
  border: none;
  outline: none;
  width: auto;
  margin-right: 35px;
  transition: all 0.6s;
}
.btn-footer:hover , .btn-footer:focus
{
  color: var(--theme-orange);
}

.btn-footer-icon
{
  background-color: transparent;
  color: var(--theme-white);
  font-size: 1.1em;
  padding: 0px;
  font-weight: 600;
  border: none;
  outline: none;
  width: auto;
  margin-right: 25px;
  transition: all 0.6s;
}
.w-60
{
  width: 60%;
}
.btn-footer-icon:hover , .btn-footer-icon:focus
{
  color: var(--theme-orange);
}
.height-60vh-header
{
  height: 50vh;
}
.header-bg-image
{
  background-image: url("https://soltechindia.co.in/images/mainpage-bg-image-2.jpg");
  background-size: cover;
  z-index: -2;
}
.header-blue-wash
{
  background: linear-gradient(to right , rgba(0, 0, 0, 0.354) , rgba(255, 115, 0, 0.444));
  /* background-color: rgba(255, 115, 0, 0.444); */
  position: absolute;
  top: 0px;
  z-index: -1;
}
.header-content 
{
  position: absolute;
  top: 0px;
  z-index: 1;
  height: 100%;
}
.about-us-main-image
{
  position: absolute;
  top: 20px;
  width: 80%; 
  height: 60vh;
  z-index: 0;
  object-fit: cover;
}
.position-relative-css
{
  position: relative;
}
.about-us-top-image
{
  box-shadow: -10px 10px 10px var(--theme-black);
  width: 30%;
  z-index: 1;
  position: absolute;
  top: -20px;
  right: 30px;
  object-fit: cover;
}
.position-relative-universal
{
  position: relative;
  height: 60vh;
}
.services-nav-btn:hover,
.services-nav-btn:focus {
  color: black;
  border-bottom: 2px solid var(--theme-dark-grey);
}
.services-nav-btn {
  background-color: transparent;
  border: 2px solid transparent;
  z-index: 5;
  text-align: center;
  font-weight: 800;
  width: auto;
  padding: 10px 0px;
  color: var(--theme-dark-grey);
  font-size: 1.1em;
}
.height-70vh
{
  height: 50vh;
}
.transition-half-sec
{
  transition: all 0.5s;
}
.image-btn:hover,
.image-btn:focus {
  border: 3px solid var(--theme-red);
  transform: scale(1.3);
}
.image-btn {
  padding: 0px;
  border: 3px solid transparent;
  background-color: transparent;
  transition: all 0.5s ease;
  width: 130px;
  height: 130px;
  margin: 20px 20px;
}
.letter-spacing-2px {
  letter-spacing: 1.2px;
  line-height: 25px;
}
.overflow-x-scroll
{
  overflow-x: scroll;
}
.products-info-section
{
  height: 200vh;
}
.border-top-orange 
{
  border-top: 1px solid var(--theme-orange);
}
.option-btn
{
  background-color: var(--theme-orange);
  padding: 10px 15px;
  border: none;
  color: white;
  outline: none;
  font-size: 1em;
  /* margin: 0px 20px; */
  width: auto;
}
.option-btn:hover , .option-btn:focus
{
  background-color: var(--theme-black);
  padding: 10px 15px;
  border: none;
  color: white;
  outline: none;
  font-size: 1em;
}
.product-image
{
  width: 100%;
}
.border-grey
{
  transition: all 0.6s;
  border: 1px solid #bbb8b8;
}
.border-grey:hover, .border-grey:focus
{
  border: 1px solid var(--theme-orange);
}
.card-shadow
{
  box-shadow: 5px 5px 10px var(--theme-grey);
  transition: all 0.6s;
}
.card-shadow:hover , .card-shadow:focus{
  box-shadow: 8px 8px 15px var(--theme-grey);
}
.border-orange-thin
{
  border: 0.5px solid orange;
}
.w-nav-btn-container
{
  width: 3em;
  height: 10vh;
}
.position-relative-top
{
  position: relative;
  top: 0px;
}
.nav-btn-container
{
  width: 15%;
  height: 100%;
  /* position: relative; */
}
.height-navbar
{
  height: 10vh;
}
.products-dropdown
{
  width: 20vw;
  /* position: relative; */
  transform-origin: top;
  transition: all 0.6s;
  /* bottom: -0%; */
}
.scale-y-0
{
  transform: scaleY(0);
}
.scale-y-1
{
  transform: scaleY(1);
}
.in-stock
{
  position: absolute;
  top: 0px;
  right: 0px;
}
.product-card
{
  transition: all 0.6s;
  background-color: var(--theme-grey);
}
.product-card:hover, .product-card:focus
{
  /* transition: all 0.6s; */
  background-color: var(--theme-orange);
}
.sticky-btn-container
{
  position: fixed;
  right: 10px;
  /* top: 50vh; */
  bottom: 0vh;
  z-index: 99;
}
.translate-x-50
{
  transform: translateX(-40%);
}
.vision-image
{
  height: 40vh;
  object-fit: cover;
}
.height-content
{
  min-height: 50vh;
}
.border-left-grey
{
  border-left: 0.5px solid var(--theme-grey);
  max-height: 80vh;
  overflow-y: scroll;
}
.btn-product
{
  background-color: transparent;
  border: 1px solid black;
  padding: 3px 10px;
  color: black;
  font-size: 0.8em;
  outline: none;
  transition: all 0.6s;
}
.btn-product:hover , .btn-product:focus
{
  background-color: black;
  color: white;
}
.product-image-container
{
  position: sticky;
  top: 25vh;
  left: 0px;
}
.border-theme-orange
{
  background-color: var(--theme-black);
  border: 2px solid var(--theme-orange);
}
.position-right-sticky
{
  position: sticky;
  top: 25vh;
  right: 0px;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0px; 
}
.overflow-hidden-css
{
  overflow: hidden;
}
.product-image-css
{
  height: 50vh;
  object-fit: cover;
}
.product-image-homepage
{
  width: 100%;
  height: 40vh;
  object-fit: cover;
}
.min-height-80vh
{
  min-height: 80vh;
}